/* eslint-disable jsx-a11y/control-has-associated-label */
import { useState, useRef } from 'react';

import { useInView } from 'react-intersection-observer';
import { Link } from 'react-router-dom';

import {
  Arrow,
  arrowDown, Fire,
} from '../styles/icons';

import Google from '../assets/icon/google.png';
import Facebook from '../assets/icon/facebook.png';
import X from '../assets/icon/x.png';
import Instagram from '../assets/icon/instagram.png';
import Yotube from '../assets/icon/youtube.png';
import Gif1 from '../assets/image/Gif1.gif';
import Gif2 from '../assets/image/Gif2.gif';
import Gif3 from '../assets/image/Gif3.gif';
import Gif4 from '../assets/image/Gif4.gif';

import s from '../styles/target.module.scss';
import { Helmet } from 'react-helmet';

export const Target = () => {
  const [card1, inViewCard1] = useInView();
  const [card2, inViewCard2] = useInView();
  const [card3, inViewCard3] = useInView();
  const [card4, inViewCard4] = useInView();
  const [card5, inViewCard5] = useInView();

  return (
    <>
    <Helmet>
        <title>Precision Targeted Advertising - OffTheGrid</title>
        <meta name="description" content="OffTheGrid provides expert targeted advertising on Google and Facebook, along with comprehensive SMM services. Elevate your brand with precision advertising strategies tailored to your needs." />
        <meta name="keywords" content="targeted advertising, Google ads, Facebook ads, social media marketing, SMM, brand elevation, digital marketing" />
        <meta property="og:title" content="Precision Targeted Advertising - OffTheGrid" />
        <meta property="og:description" content="Expert targeted advertising services by OffTheGrid. Enhance your brand with precision strategies on Google, Facebook, and social media platforms." />
        <meta property="og:image" content="%PUBLIC_URL%/favicon.ico" />
        <meta property="og:url" content="https://offthegridlab.com/target" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Precision Targeted Advertising - OffTheGrid" />
        <meta name="twitter:description" content="Expert targeted advertising services by OffTheGrid. Enhance your brand with precision strategies on Google, Facebook, and social media platforms." />
        <meta name="twitter:image" content="%PUBLIC_URL%/favicon.ico" />
      </Helmet>
      <section className={s.title__container}>
        <div className={s.title__header}>
          <h1>
            <span className={s.span__green}>Precision</span>
            –Targeted Advertising Made Simple
          </h1>
        </div>
        <div className={s.title__suptitle}>
          {/* eslint-disable-next-line react/button-has-type */}
          <Link className={s.title__btn} to="/contacts">Contact us</Link>
          <p className={s.title__sup}>
            At OffTheGrid, we&#39;re your go-to
            {' '}
            <span className={s.span__pink}>experts</span>
            {' '}
            <span className={s.span__green}>for targeted</span>
            <span className={s.span__orange}> advertising</span>
            {' '}
            on Google and Facebook, alongside comprehensive SMM services.
          </p>
        </div>
      </section>

      <section className={s.about}>
        <div className={s.about__top}>
          <img alt="arrow down" src={arrowDown} />
          <div className={s.about_badge}>scroll down</div>
          <img alt="arrow down" src={arrowDown} />
        </div>
        {/* {
          window.innerWidth > 767
            ? ( */}
              <h3 className={`${s.about_title} ${s.adapt__desc}`}>
                Here&#39;s how
                <img alt="icon Fire" src={Fire} />
                we can
                <br />
                elevate
                <img alt="icon Arrow" src={Arrow} />
                your brand:
              </h3>
            {/* )
            : ( */}
              <h3 className={`${s.about_title} ${s.adapt__mob}`}>
                Here&#39;s how
                <img alt="icon Fire" src={Fire} />
                we can
                <img alt="icon Arrow" src={Arrow} />
                elevate your brand:
              </h3>
            {/* )
        } */}
        <section className={s.choice}>
          <div className={`${s.cards} ${s.choise__one}`}>
            <div ref={card4} className={`${s.card} ${s.card4} ${inViewCard4 && s.card4__bg}`}>
              <div className={s.card__flex}>
                <button className={s.switch} type="button">
                  <div className={s.switch_circle} />
                </button>
                <div className={s.card__icons}>
                  <img alt="google" src={Google} />
                  <img alt="google" src={Facebook} />
                </div>
              </div>
              <div className={s.card__wrapper}>
                <h4 className={s.card_title}>Google & Facebook Advertising</h4>
                <p className={s.card_description}>
                  Place your brand front and center with targeted ads that
                  reach your audience at the right time and place.
                </p>
              </div>
            </div>
            <div ref={card5} className={`${s.card} ${s.card5} ${inViewCard5 && s.card5__bg}`}>
              <div className={s.card__flex}>
                <button className={s.switch} type="button">
                  <div className={s.switch_circle} />
                </button>
                <div className={s.card__icons}>
                  <img alt="google" src={Facebook} />
                  <img alt="google" src={Instagram} />
                  <img alt="google" src={X} />
                  <img alt="google" src={Yotube} />
                </div>
              </div>
              <div className={s.card__wrapper}>
                <h4 className={s.card_title}>Social Media Marketing</h4>
                <p className={s.card_description}>
                  Engage and grow your audience with strategic content that fosters genuine connections.
                </p>
              </div>
            </div>
          </div>
        </section>
      </section>
      <section className={s.our__process}>
        <h1>Our Process</h1>
        <div className={s.process__container}>
          <div className={s.process__card}>
            <h4>01</h4>
            <img alt="" src={Gif1} />
            <h2>Understand Your Business</h2>
            <p>
              We start by getting to know your brand, audience,
              and goals to ensure our strategies align with your vision.
            </p>
          </div>
          <div className={s.process__card}>
            <h4>02</h4>
            <img alt="" src={Gif2} />
            <h2>Develop Custom Strategies</h2>
            <p>
              We craft tailored advertising and SMM strategies that target your ideal customers effectively.
            </p>
          </div>
          <div className={s.process__card}>
            <h4>03</h4>
            <img alt="" src={Gif3} />
            <h2>Optimize Continuously</h2>
            <p>
              Your campaigns are regularly analyzed and optimized for the best results,
              adapting to digital trends and audience behaviors.
            </p>
          </div>
          <div className={s.process__card}>
            <h4>04</h4>
            <img alt="" src={Gif4} />
            <h2>Transparent Reporting</h2>
            <p>
              Receive clear reports on your campaign&#39;s
              performance, with insights and adjustments clearly communicated.
            </p>
          </div>
        </div>
      </section>
      <section className={s.join}>
        <h1>Join us at OffTheGrid</h1>
        <p>where your brand&#39;s potential is limitless. Let&#39;s achieve success together.</p>
        {/* eslint-disable-next-line react/button-has-type */}
        <Link to="/contacts">Contact us</Link>
      </section>
      <section className={s.choice}>
        <div className={s.title__wrapper}>
          <h2 className={s.title}>When you choose us, you choose:</h2>
          <div className={s.subtitle}>
            <p>We are a studio that doesn&apos;t just do the job.</p>
            <p>We create art for your business.</p>
          </div>
        </div>
        <div className={s.cards}>
          <div ref={card1} className={`${s.card} ${s.card1} ${inViewCard1 && s.card1__bg}`}>
            <button className={s.switch} type="button">
              <div className={s.switch_circle} />
            </button>
            <div className={s.card__wrapper}>
              <h4 className={s.card_title}>Creative Solutions</h4>
              <p className={s.card_description}>
                We don&apos;t take the easy way out. We explore, experiment, and craft unique solutions that set your
                brand apart from the crowd.
              </p>
            </div>
          </div>
          <div ref={card2} className={`${s.card} ${s.card2} ${inViewCard2 && s.card2__bg}`}>
            <button className={s.switch} type="button">
              <div className={s.switch_circle} />
            </button>
            <div className={s.card__wrapper}>
              <h4 className={s.card_title}>Versatility</h4>
              <p className={s.card_description}>
                From web development to 3D design and marketing, we&apos;ve brought together the best talents in various
                fields to provide you with a complete range of services.
              </p>
            </div>
          </div>
          <div ref={card3} className={`${s.card} ${s.card3} ${inViewCard3 && s.card3__bg}`}>
            <button className={s.switch} type="button">
              <div className={s.switch_circle} />
            </button>
            <div className={s.card__wrapper}>
              <h4 className={s.card_title}>Your Growth Partner</h4>
              <p className={s.card_description}>
                We&apos;re not just service providers. We&apos;re your partners in achieving success. Together, we grow
                and evolve.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className={s.start}>
        <div className={s.start__title}>
          <h1>Quick Start to Success</h1>
          <p>Step by step</p>
        </div>
        <div className={s.start__container}>
          <div className={`${s.start__card1} ${s.start__item}`}>
            <h2>Step 01</h2>
            <div>
              <h1>Fast Consultation</h1>
              <p>Quick kick off meeting to understand your brand and goals.</p>
            </div>
          </div>
          <div className={`${s.start__card2} ${s.start__item}`}>
            <h2>Step 02</h2>
            <div>
              <h1>Strategy Crafting</h1>
              <p>Swiftly develop a tailored advertising strategy for immediate action.</p>
            </div>
          </div>
          <div className={`${s.start__card3} ${s.start__item}`}>
            <h2>Step 03</h2>
            <div>
              <h1>Launch Campaign</h1>
              <p>Rapidly launch your campaign to start targeting your audience without delay.</p>
            </div>
          </div>
          <div className={`${s.start__card4} ${s.start__item}`}>
            <h2>Step 04</h2>
            <div>
              <h1>Optimize</h1>
              <p>Continuously refine your campaign for optimal performance, quickly implementing adjustments.</p>
            </div>
          </div>
          <div className={`${s.start__card5} ${s.start__item}`}>
            <h2>Step 05</h2>
            <div>
              <h1>Reporting</h1>
              <p>Receive prompt, insightful reports to keep you in the loop on your campaign’s progress.</p>
            </div>
          </div>
        </div>
      </section>
      <section className={s.accelerate}>
        <h1>Accelerate with Off The Grid</h1>
        <p>
          We value your time. Our
          {' '}
          <span className={s.span__green}>streamlined process</span>
          {' '}
          ensures your
          campaigns are up and running fast,
          {' '}
          <span className={s.span__pink}>delivering results</span>
          {' '}
          sooner. Let&#39;s
          {' '}
          <span className={s.span__orange}>drive your brand</span>
          {' '}
          forward, efficiently.
        </p>
      </section>
    </>
  );
};

export default Target;
